import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo_MAKCI-class_small-no-border.png";
import { ReactComponent as Menu } from "../images/icons/menu-button.svg";

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);

    const y = element.getBoundingClientRect().top + window.pageYOffset - 65;

    window.scrollTo({top: y})

    console.log(window.pageYOffset);
  }

  return (
    <header className='Header'>
      <div className='header-wrapper'>
        <div className='header-logo-wrapper'>
          <img className='header-logo' src={logo} alt='logo' />
        </div>
        <a href='tel:+7914-975-9055' className='header-phone-number'>
          +7(914)-975-9055
        </a>
        <div className='header-menu-button-wrapper'>
          <Menu onClick={() => setOpenMenu((state) => !state)} />
        </div>
      </div>
      <nav className={"nav-menu"}>
        <ul className={"nav-menu-list" + (!openMenu ? " nav-menu-hidden" : "")}>
          <li onClick={() => scrollToElement("our-courses")}>Наши направления</li>
          <li onClick={() => scrollToElement("our-results")}>Наши результаты</li>
          <li onClick={() => scrollToElement("teachers")}>Преподаватели</li>
          <li onClick={() => scrollToElement("our-results")}>Как проехать</li>
          <li onClick={() => scrollToElement("our-results")}>Контакты</li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
