import { gql } from "@apollo/client";

const login = gql`
  mutation ($login: String!, $password: String!) {
    login(login: $login, password: $password)
  }
`;

export {
  login
};