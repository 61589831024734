import { useRef, useEffect, useState } from "react";
import { ReactComponent as Upload } from "../images/icons/upload.svg";

const allowedExtensions = ["image/jpeg", "image/png", "image/webp", "image/svg+xml"];

function ImagePicker({ id, file, setFile, setError }) {
  const [dropOver, setDropOver] = useState(false);

  const inputRef = useRef(null);
  const dropArea = useRef(null);

  // event handler on file upload with error handling
  const fileUploadHandler = () => {
    if (inputRef.current.files.length === 0) return;

    if (inputRef.current.files.length === 1) {
      const fileExt = inputRef.current.files[0].type;
      if (allowedExtensions.length === 0 || allowedExtensions.includes(fileExt)) {
        setError([]);
      } else {
        inputRef.current.value = null;
        setError([`Files of type ${fileExt} are not allowed.`]);
      }
    } else {
      inputRef.current.value = null;
      setError(["Only 1 file is allowed for submission."]);
    }

    setFile(inputRef.current.files[0]);
  };

  //----------------< drop upload handlers >--------------
  const dropHandlerEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDropOver(true);
  };

  const dropHandlerLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDropOver(false);
  };

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const dt = e.dataTransfer;
    if (dt && dt.files) {
      inputRef.current.files = dt.files;
      fileUploadHandler();
    }
  };

  useEffect(() => {
    if (dropArea.current !== null) {
      dropArea.current.addEventListener("dragenter", dropHandlerEnter, false);
      dropArea.current.addEventListener("dragover", dropHandlerEnter, false);
      dropArea.current.addEventListener("dragleave", dropHandlerLeave, false);
      dropArea.current.addEventListener("drop", dropHandler, false);
    }
  }, [dropArea]);

  return (
    <div className='ImagePicker'>
      <label
        className={"ImagePicker-wrapper" + (dropOver ? " dropping-file" : "")}
        ref={dropArea}
        htmlFor={"image-input-" + id}
      >
        <Upload className={'add-image-button' + (file ? " image-uploaded" : "")} />
      </label>
      {file ? (
        <img
          src={URL.createObjectURL(file)}
          alt='uploaded'
          className='uploaded-image'
        />
      ) : (
        <></>
      )}
      <input
        id={"image-input-" + id}
        className='image-input'
        type='file'
        onChange={fileUploadHandler}
        ref={inputRef}
      />
    </div>
  );
}

export default ImagePicker;
