import { useState } from "react";
import { ReactComponent as ArrowLeft } from "../images/icons/Arrow-left.svg";
import { ReactComponent as ArrowRight } from "../images/icons/Arrow-right.svg";

function Slider({ data }) {
  const [slide, setSlide] = useState(0);

  const containsTitle = data.filter((slide) => slide.title).length > 0;

  return (
    <div className='Slider'>
      <div className='slider-wrapper'>
        <div className='slider-photo-area' style={{ left: -100 * slide + "%" }}>
          {data.map(({ name, type, alt }, index) => {
            let element;

            let slideStatus = "";
            if (index === slide) slideStatus = " current-slide";

            if (type === "photo") {
              element = (
                <div key={index} className='slide'>
                  <img
                    className={"slider-photo" + slideStatus}
                    src={require("../images/" + name)}
                    alt={alt}
                  />
                </div>
              );
            } else if (type === "video") {
              element = (
                <div key={index} className='slide'>
                  <iframe
                    height={250}
                    className={"slide slider-video" + slideStatus}
                    title={alt}
                    src={name}
                    allowFullScreen
                  ></iframe>
                </div>
              );
            }
            return element;
          })}
        </div>
        {containsTitle ? <h4 className={"slider-title" + (!data[slide].title ? " empty" : "")}>{data[slide].title || "no title"}</h4> : <></>}
        <div className='slider-controls'>
          <button
            className='slider-controls-button'
            onClick={() => setSlide((index) => (index - 1 < 0 ? data.length - 1 : index - 1))}
          >
            <ArrowLeft />
          </button>
          <div className="slider-jump-to-buttons-area">
            {data.map((element, index) => {
              return (
                <button
                  key={index}
                  onClick={() => setSlide(index)}
                  className={
                    "slider-jump-to-button" +
                    (index === slide ? " slider-jump-to-button-active" : "")
                  }
                ></button>
              );
            })}
          </div>
          <button
            className='slider-controls-button'
            onClick={() =>
              setSlide((index) => (index + 1 >= data.length ? 0 : index + 1))
            }
          >
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Slider;
