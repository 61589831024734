import { useState } from "react";
import { useMutation } from "@apollo/client";

import { login as loginMutation } from "../queries/queries";

function Login({ setJwtToken }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [loginUser] = useMutation(loginMutation);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    loginUser({
      context: {
        header: {
          Authorization: "Bearer " + localStorage.getItem("maxiClassJwtToken"),
        },
      },
      variables: {
        login,
        password,
      },
    })
      .then(({ data }) => {
        setJwtToken(data.login);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  };

  return (
    <main className='Login'>
      <div className='login-card'>
        <h2>Авторизация</h2>
        <form className='login-form' onSubmit={onSubmitHandler}>
          <label htmlFor='login'>Логин</label>
          <input
            type='text'
            id='login'
            name='login'
            onChange={(e) => setLogin(e.target.value)}
            value={login}
          />
          <label htmlFor='password'>Пароль</label>
          <input
            type='password'
            id='password'
            name='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <input type='submit' value='Войти' />
        </form>
      </div>
    </main>
  );
}

export default Login;
