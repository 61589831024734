function PriceCard({title, individual = false, price, frequency, description, minimal = false}) {
  return (
    <div className="PriceCard">
      <h4 className="price-card-title">{title}</h4>
      <hr className="price-card-hr"/>
      <span className="price-card-course-type">{individual ? "индивидуальные" : "групповые"}</span>
      <p className="price-card-price"><span>{price}</span> в {frequency}</p>
      {description ? <p className="price-card-description">{description}</p> : <></>}
      {minimal ? <span className="price-card-minimal">минимальный</span> : <></>}
    </div>
  )
}

export default PriceCard;