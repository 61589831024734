function Admin({jwtToken}) {

  return (
    <main className="Admin">
      <div className="admin-panel-wrapper">
        <h4>Админ панель</h4>
      </div>
    </main>
  )
}

export default Admin;