import Cabinet from "../images/home-welcome-image.jpg";

import PriceCard from "../components/PriceCard";
import Slider from "../components/Slider";

let coursesPrice = [
  {
    title: "Английский язык",
    price: "4000",
    frequency: "месяц",
    description: "Занятия 2 раза в неделю",
    minimal: true,
  },
  {
    title: "Математика",
    price: "3000",
    frequency: "месяц",
    description: "Занятия 2 раза в неделю",
    minimal: true,
  },
  {
    title: "Математика",
    individual: true,
    price: "1000",
    frequency: "час",
  },
];

const ourResults = [
  {
    name: "https://www.youtube.com/embed/cTKFXSn-sn0",
    alt: "",
    type: "video",
  },

  {
    name: "https://www.youtube.com/embed/8F5cHJHtxcw",
    alt: "",
    type: "video",
  },
  {
    name: "https://www.youtube.com/embed/q3F8dle-xAE",
    alt: "",
    type: "video",
  },
  {
    name: "https://www.youtube.com/embed/DggIhY6aG1E",
    alt: "",
    type: "video",
  },
];

const ourTeachers = [
  {
    name: "alla-photo.jpeg",
    alt: "Алла Павловна стоит с учебником в книжном магазине",
    type: "photo",
    title: "Федченко Алла Павловна",
  },
  {
    name: "sergei-photo.jpeg",
    alt: "Сергей Федченко сидит на стуле в костюме",
    type: "photo",
    title: "Федченко Сергей Александрович",
  },
];

function Home() {
  return (
    <main className='Home'>
      <div id="about-centre" className='welcome-section'>
        <div className='welcome-section-text'>
          <h1>Макси-Класс</h1>
          <h3>Детский развивающий центр с 2016 года</h3>
        </div>
        <img src={Cabinet} alt='кабинет' className='landing-photo' />
      </div>
      <section id="our-courses" className='our-courses'>
        <h1>Наши направления</h1>
        <ul className='our-courses-list'>
          <li>Английский Язык</li>
          <li>Математика</li>
          <li>Подготовка к школе</li>
          <li>Программирование</li>
        </ul>
      </section>
      <hr />
      <section id="english-course" className='english-course'>
        <h1>Английский язык</h1>
        <h2 className='english-course-age'>Дети от 3 до 12 лет</h2>
        <ul className='english-course-advantages'>
          <h2>Наши преимущества</h2>
          <li>
            Дети занимаются по методике В.Н. Мещеряковой с погружением в языковую
            среду.
          </li>
          <li>Уроки ведутся только на английском языке.</li>
          <li>
            80% обучения это языковая практика. Мы с нашими учениками постоянно
            играем, поем, разыгрываем сценки, делаем упражнения.
          </li>
          <li>Каждый код дети участвуют в двух мюзиклах на английском языке.</li>
          <li>
            Быстрый результат. Уже после первого года обучения дети могут поддержать
            20 минутный диалог на английском языке.
          </li>
        </ul>
        <span className='english-course-important'>
          ВАЖНО!!! Набор в группы ведется в возрасте от 4 до 7 лет.
        </span>
        <div id="our-results" className="english-course-results">
          <h2>Наши результаты</h2>
          <Slider data={ourResults} />
        </div>
      </section>
      <hr />
      <section id="teachers" className='our-teachers'>
        <h1>Наши преподаватели</h1>
        <Slider data={ourTeachers} />
      </section>
      <hr />
      <section className='courses-cost'>
        <h1>Стоимость занятий</h1>
        <div className='courses-cost-cards'>
          {coursesPrice.map(
            (
              {
                title,
                individual = false,
                price,
                frequency,
                description,
                minimal = false,
              },
              index
            ) => (
              <PriceCard
                key={index}
                title={title}
                individual={individual}
                price={price}
                frequency={frequency}
                description={description}
                minimal={minimal}
              />
            )
          )}
        </div>
      </section>
    </main>
  );
}

export default Home;
