import "./styles/App.css";

import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import Header from "./components/Header";
import Home from "./pages/Home";
import ImagePicker from "./components/ImagePicker";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Footer from "./components/Footer";

const apiUrl = "http://localhost:4200";

const client = new ApolloClient({
  uri: apiUrl + "/graphql",
  cache: new InMemoryCache(),
  fetchOption: {
    mode: "cors",
  },
});

function App() {
  /* const [file, setFile] = useState(null);

  const uploadImage = () => {
    let formData = new FormData();

    formData.append("image", file);

    fetch(apiUrl + "/api/image", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.image.filename) {
          console.log(res.image.filename);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }; */

  let [jwtToken, setJwtToken] = useState(
    localStorage.getItem("maxiClassJwtToken") === "null"
      ? null
      : localStorage.getItem("maxiClassJwtToken")
  );

  // saves jwt token
  useEffect(() => {
    localStorage.setItem("maxiClassJwtToken", jwtToken);
  }, [jwtToken]);

  return (
    <ApolloProvider client={client}>
      <div className='App'>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route
            path='/admin'
            element={jwtToken ? <Admin /> : <Login setJwtToken={setJwtToken} />}
          />
        </Routes>
        <Footer />
        {/* <ImagePicker
        id='1'
        file={file}
        setFile={setFile}
        setError={(e) => console.log(e)}
      />
      <button onClick={uploadImage}>Upload</button> */}
      </div>
    </ApolloProvider>
  );
}

export default App;
